import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MainService } from '../../services/main.service';
import { Api } from '../../common/api';
import { IProperty } from '../../common/property';
import { Constants } from '../../common/constants';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  value: any;
  categoryData: any;
  pageChangedNumber:number;
  itemsPerPageValue:number;
  public parameter: IProperty = {};
  modelCustomize: any;
  model:any;
  modalRef: BsModalRef;
  deleteId: any;
  config: any;

  constructor(
    private modalService: BsModalService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private mainService: MainService,
    private api: Api,
    public constants: Constants,
    private router: Router
  ) {
    this.parameter.page = 1;
    this.config = {
      backdrop: true,
      ignoreBackdropClick: true,
      animated: true, 
      keyboard: false
    }
   }

  getPage(page) {
    this.parameter.page = page;
  }

  pageChanged(event: any): void {
    this.pageChangedNumber = event.page;
    this.itemsPerPageValue = event.itemsPerPage
    //console.log('Page changed to: ' + event.page);
    //console.log('Number items per page: ' + event.itemsPerPage);
  }

  editItem(categories_id: string) {
    this.router.navigate(['/edit-user', categories_id]);
  }


  ngOnInit(): void {
    this.getUsers()
  }

  getUsers() {

    const offset = (this.parameter.page * this.constants.itemsPerPage) - this.constants.itemsPerPage;
    const input = {
      limit: this.constants.itemsPerPage,
      skip: offset
    }
    this.mainService.getDataAptWithParams(this.api.paths.getUsers, input).subscribe(
      success => {
        this.modelCustomize = success['data'];
        this.parameter.total = success['data'].length;
        //console.log("this.parameter.total",this.parameter.total)
      });
  }

  changeStatus(userId, status){
    const input = {
      userId: userId,
      isActive: status
    };
    this.mainService.postDataApi(this.api.paths.editUser, input).subscribe(
      success => {
        this.toastrService.success(success['message']);
        this.getUsers()
      });
  }

  opendeletemodal(id, template: TemplateRef<any>) {
    this.deleteId = id;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-md' }, this.config)
    );
  }

  delete(delId){
    //console.log("Deleted Item Called", delId)

    this.mainService.deleteDataApi(this.api.paths.deleteUser+'?userId='+delId, {}).subscribe(
      success => {
        this.modalRef.hide()
        this.toastrService.success('User deleted successfully');
        this.getUsers()
      });
  }

  copyMessage(val: string){
    const newVal = 'https://eu.europeanvisa.in/user/'+val
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = newVal;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
